<script setup lang="ts">
import { TranslationKeys } from '~/i18n/TranslationKeys'
import { SpSvg } from '~~/src/autogen/SpSvg'
import { IdModal } from '~~/src/constants/IdModal'
import { useAccommodationsStore } from '~~/src/store/accommodations'
import { useEventsStore } from '~~/src/store/events'
import { useModalStore } from '~~/src/store/modals'
import { useModifiersStore } from '~~/src/store/modifiers'

const eventStore = useEventsStore()
const accommodationStore = useAccommodationsStore()
const modalStore = useModalStore()
const modifiersStore = useModifiersStore()

const accommodations = computed(() => eventStore.selectedAccommodations)
const accommodationNames = computed(() =>
	accommodations.value.map((el) => accommodationStore.getAccommodationById(el)!.name)
)
const selectedAccommodationIndex = ref(0)
const selectedAccommodation = computed(() => accommodations.value[selectedAccommodationIndex.value])
const currentCurrency = computed(() => accommodationStore.getAccommodationById(selectedAccommodation.value)!.currency)
const selectedRoomType = ref(
	accommodationStore.getRoomTypesByAccommodationId(selectedAccommodation.value).filter((roomType) => {
		return !roomType.isIndexed
	})[0].id
)
const hasModifiers = computed(() => modifiersStore.modifiers.length > 0)
const isBlurred = computed(() => !!accommodationStore.getRoomTypeById(selectedRoomType.value)?.isIndexed)

const onAccommodationChange = (position: number) => {
	selectedAccommodationIndex.value = position
	selectedRoomType.value = accommodationStore.getRoomTypesByAccommodationId(selectedAccommodation.value)[0].id
}
const onRoomTypeChange = (id: number) => (selectedRoomType.value = id)

const onModifiersRemove = () => {
	modalStore.addModal(IdModal.EventRemoveModifiers)
}
</script>

<template>
	<div class="flex h-full flex-col">
		<AnimationCollapse>
			<div v-if="hasModifiers" class="mb-2 flex items-center justify-between rounded bg-yellow-100 p-2">
				<div class="flex items-center gap-2">
					<CommonIcon :icon-name="SpSvg.BasicAlertCircle" fill="fill-yellow-900" />
					<CommonText :text="TranslationKeys.WARNING_MODIFIERS_AVAILABLE" class="text-yellow-900" />
				</div>
				<div>
					<CommonText
						:text="TranslationKeys.REMOVE"
						@click="onModifiersRemove"
						class="cursor-pointer text-yellow-900 hover:underline"
					/>
				</div>
			</div>
		</AnimationCollapse>

		<div class="grid h-full w-full grid-cols-[1fr_1fr] grid-rows-[min-content_1fr] gap-3">
			<div class="grid w-full">
				<CommonLabel v-if="accommodationNames.length > 1" :text="TranslationKeys.ACCOMMODATIONS">
					<CommonDropdown
						:entries="accommodationNames"
						:selected-entry="selectedAccommodationIndex"
						@click="onAccommodationChange"
					/>
				</CommonLabel>
			</div>
			<div class="grid w-full">
				<CommonLabel :text="TranslationKeys.ROOM_TYPE">
					<RoomTypeDropdown
						:accommodation-id="selectedAccommodation"
						:selected-id="selectedRoomType"
						@change="onRoomTypeChange"
						:hideIndexedRooms="false"
						:disable-indexed-rooms="true"
						:hideDerivedRooms="true"
					/>
				</CommonLabel>
			</div>
			<div class="col-span-2 h-full">
				<SpBlur :is-blurred="isBlurred">
					<ChartsEventPreview
						:event-preview="eventStore.getEventPreviewByAccommodationId(selectedAccommodation as unknown as string)"
						:current-preview="eventStore.getEventPreviewByAccommodationId(selectedAccommodation as unknown as string)"
						:event-with-modifiers-preview="
							eventStore.getEventWithModifiersPreviewByAccommodationId(selectedAccommodation as unknown as string)
						"
						:currency="currentCurrency"
						:selected-room-type="selectedRoomType"
						:data-selection-required="eventStore.editableEventDetails.impacts.length < 1"
					/>

					<template #placeholder class="flex flex-col justify-start">
						<SpNotification
							class="mb-6 max-w-full"
							:type="SpNotificationType.Info"
							:title="useLocale().translate(TranslationKeys.IMPACT_PREVIEW_NOT_AVAILABLE_TITLE)"
							:text="useLocale().translate(TranslationKeys.IMPACT_PREVIEW_NOT_AVAILABLE_BODY)"
							:dismissable="false"
						/>
					</template>
				</SpBlur>
			</div>
		</div>
	</div>
</template>
